<template>
  <div>
    <template v-if="!loading">
      <h1
        class="
        border-0
        font-semibold
        border-b border-solid border-gray-400
        py-3
        px-2
        text-blackdok text-xl
      "
      >
        Informations confidentielles
      </h1>

      <ValidationObserver
        ref="observer"
        class="grid gap-2 grid-cols-1 xl:grid-cols-2 px-2 py-5"
      >
        <ValidationProvider
          name="prenom"
          rules="required"
          class="w-full"
          v-slot="{ errors }"
        >
          <dok-input
            size="lg"
            d-placeholder="Prénom"
            :d-type="'text'"
            :d-model.sync="user.firstName"
            label="Prénom :"
            :custom-class="
              errors[0] ? 'border-red-600 w-full' : 'border-borderInput w-full'
            "
            v-model="user.firstName"
            :d-disbled="user.firstName !== '' ? true : false"
          >
          </dok-input>
          <p
            v-if="errors[0]"
            class="text-red-600 text-sm font-semibold uppercase mt-1"
          >
            <i class="fas fa-exclamation-circle"></i> Veuillez remplir ce champ
          </p>
        </ValidationProvider>
        <ValidationProvider
          name="Nom de famille"
          rules="required"
          class="w-full"
          v-slot="{ errors }"
        >
          <dok-input
            size="lg"
            d-placeholder="Nom de famille"
            :d-type="'text'"
            :d-model.sync="user.lastName"
            label="Nom de famille :"
            :custom-class="
              errors[0] ? 'border-red-600 w-full' : 'border-borderInput w-full'
            "
            v-model="user.lastName"
            :d-disbled="user.lastName !== '' ? true : false"
          >
          </dok-input>
          <p
            v-if="errors[0]"
            class="text-red-600 text-sm font-semibold uppercase mt-1"
          >
            <i class="fas fa-exclamation-circle"></i> Veuillez remplir ce champ
          </p>
        </ValidationProvider>
        <ValidationProvider
          name="Nom commercial"
          rules="required"
          class="w-full"
          v-slot="{ errors }"
        >
          <dok-input
            size="lg"
            :d-placeholder="$t('nameComercial')"
            d-type="text"
            :d-model.sync="user.localCommercialName"
            v-model="user.localCommercialName"
            :custom-class="
              errors[0] ? 'border-red-600 w-full' : 'border-borderInput w-full'
            "
            :label="$t('nameComercial')"
          >
          </dok-input>
        </ValidationProvider>
        <div class="flex flex-col gap-y-1">
          <label class="font-EffraR text-base text-gray-800"
            >Date de naissance :
          </label>
          <birth-day
            :date-save="user.birthday"
            :on-set-date="setBirthDay"
            customClass="border-2 border-solid border-borderInput rounded px-1 py-3 w-full font-EffraR text-black appearance-none h-4848 bg-transparent"
          ></birth-day>
        </div>
        <ValidationProvider
          name="CIN"
          rules="required"
          class="w-full"
          v-slot="{ errors }"
        >
          <dok-input
            size="lg"
            d-placeholder="CIN"
            :d-type="'text'"
            :d-model.sync="user.cin"
            label="CIN :"
            :custom-class="
              errors[0] ? 'border-red-600 w-full' : 'border-borderInput w-full'
            "
            v-model="user.cin"
            :d-disbled="currentUser.cin !== '' ? true : false"
          >
            <template v-slot:rightIcon>
              <i
                class="far fa-question-circle cursor-pointer px-3 tooltipinfo"
                v-tooltip="`${$getInfoBull('cin')}`"
              ></i>
            </template>
          </dok-input>
          <p
            v-if="errors[0]"
            class="text-red-600 text-sm font-semibold uppercase mt-1"
          >
            <i class="fas fa-exclamation-circle"></i> Veuillez remplir ce champ
          </p>
        </ValidationProvider>
        <dok-input
          size="lg"
          d-placeholder="654..."
          :d-type="'text'"
          :d-model.sync="user.professionalInfo.INPE"
          label="INPE :"
          v-model="user.professionalInfo.INPE"
        >
          <template v-slot:rightIcon>
            <i
              class="far fa-question-circle cursor-pointer px-3 tooltipinfo"
              v-tooltip="`${$getInfoBull('inpe')}`"
            ></i>
          </template>
        </dok-input>
        <dok-input
          size="lg"
          d-placeholder="5327/15"
          :d-type="'text'"
          :d-model.sync="user.professionalInfo.registrationNumber"
          label="N° d'inscription à l'OM :"
          :d-disbled="
            currentUser.professionalInfo.registrationNumber !== '' &&
            currentUser.professionalInfo.registrationNumber
              ? true
              : false
          "
        >
        </dok-input>
        <dok-input
          size="lg"
          d-placeholder="Numéro de mobile personnel"
          :d-type="'text'"
          :d-model.sync="user.mobile"
          label="Mobile Perso :"
          v-model="user.mobile"
        >
          <template v-slot:rightIcon>
            <i
              class="far fa-question-circle cursor-pointer px-3 tooltipinfo"
              v-tooltip="`${$getInfoBull('mobile')}`"
            ></i>
          </template>
        </dok-input>
        <ValidationProvider
          name="Numéro de mobile Professionnel"
          rules="required"
          class="w-full"
          v-slot="{ errors }"
        >
          <dok-input
            size="lg"
            d-placeholder="Numéro de mobile Professionnel"
            :d-type="'text'"
            :d-model.sync="user.mobilePro"
            label="Mobile Pro :"
            v-model="user.mobilePro"
            :custom-class="
              errors[0] ? 'border-red-600 w-full' : 'border-borderInput w-full'
            "
          >
          </dok-input>
          <p
            v-if="errors[0]"
            class="text-red-600 text-sm font-semibold uppercase mt-1"
          >
            <i class="fas fa-exclamation-circle"></i> Veuillez remplir ce champ
          </p>
        </ValidationProvider>
        <ValidationProvider
          name="email"
          rules="required"
          class="w-full"
          v-slot="{ errors }"
        >
          <dok-input
            size="lg"
            d-placeholder="Email"
            :d-type="'email'"
            :d-model.sync="user.email"
            label="Email :"
            :custom-class="
              errors[0] ? 'border-red-600 w-full' : 'border-borderInput w-full'
            "
            v-model="user.email"
            :d-disbled="user.email !== '' ? true : false"
          >
            <template v-slot:rightIcon>
              <i
                class="far fa-question-circle cursor-pointer px-3 tooltipinfo"
                v-tooltip="
                  ` Pour une question de suivi et de sécurité, il est nécessaire
                de nous
                contacter
                pour modifier votre adresse mail`
                "
              ></i>
            </template>
          </dok-input>
          <p
            v-if="errors[0]"
            class="text-red-600 text-sm font-semibold uppercase mt-1"
          >
            <i class="fas fa-exclamation-circle"></i> Veuillez remplir ce champ
          </p>
        </ValidationProvider>
        <dok-input
          size="lg"
          d-placeholder="Patente"
          :d-type="'text'"
          :d-model.sync="user.professionalInfo.patente"
          label="Patente :"
        >
        </dok-input>
        <dok-input
          size="lg"
          d-placeholder="Identifiant Commun de l'entreprise"
          :d-type="'text'"
          :d-model.sync="user.professionalInfo.commonCompanyIdentifier"
          label="ICE :"
        >
        </dok-input>
        <dok-input
          size="lg"
          d-placeholder="Registre de Commerce"
          :d-type="'text'"
          :d-model.sync="user.professionalInfo.registerCommerce"
          label="RC :"
        >
        </dok-input>
        <dok-input
          size="lg"
          d-placeholder="Numéro d'autorisation d'exercer"
          :d-type="'text'"
          :d-model.sync="user.professionalInfo.numAutorisationExecer"
          label="Numéro d'autorisation d'exercer :"
        >
          <template v-slot:iconRight>
            <i
              class="far fa-question-circle cursor-pointer px-3 tooltipinfo"
              v-tooltip="`Délivré par le secretariat general du gouvernement`"
            ></i>
          </template>
        </dok-input>
      </ValidationObserver>
      <div class="flex justify-end px-2 py-2">
        <dok-button
          type="submit"
          size="md"
          custom-class="uppercase"
          @click.native="onClick"
          >Sauvegarder</dok-button
        >
      </div>
    </template>

    <template v-if="loading">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        style="margin: auto; display: block; shape-rendering: auto"
        width="200px"
        height="200px"
        viewBox="0 0 100 100"
        preserveAspectRatio="xMidYMid"
      >
        <circle cx="30" cy="50" fill="#fd5b68" r="20">
          <animate
            attributeName="cx"
            repeatCount="indefinite"
            dur="1s"
            keyTimes="0;0.5;1"
            values="30;70;30"
            begin="-0.5s"
          ></animate>
        </circle>
        <circle cx="70" cy="50" fill="#2e6bff" r="20">
          <animate
            attributeName="cx"
            repeatCount="indefinite"
            dur="1s"
            keyTimes="0;0.5;1"
            values="30;70;30"
            begin="0s"
          ></animate>
        </circle>
        <circle cx="30" cy="50" fill="#fd5b68" r="20">
          <animate
            attributeName="cx"
            repeatCount="indefinite"
            dur="1s"
            keyTimes="0;0.5;1"
            values="30;70;30"
            begin="-0.5s"
          ></animate>
          <animate
            attributeName="fill-opacity"
            values="0;0;1;1"
            calcMode="discrete"
            keyTimes="0;0.499;0.5;1"
            dur="1s"
            repeatCount="indefinite"
          ></animate>
        </circle>
      </svg>
    </template>
  </div>
</template>

<script>
const birthDay = () => import("@/views/global-components/birthday");
import { EventBus } from "@/event-bus";
import { mapActions } from "vuex";
import { cloneDeep } from "lodash";

export default {
  data() {
    return {
      user: {},
      currentUser: {},
      loading: true
    };
  },
  components: {
    birthDay
  },
  async created() {
    await this.refreshMe();

    if (!this.user.professionalInfo) {
      this.user.professionalInfo = {};
    }
  },
  methods: {
    ...mapActions("user", ["UPDATE_PROFILE"]),
    setBirthDay: function(data) {
      this.user.birthday = data;
    },
    onClick: async function() {
      const isValid = await this.$refs.observer.validate();
      if (!isValid) return;

      this.$vs.loading({ background: "#000" });
      await this.UPDATE_PROFILE({
        data: this.user,
        onData: this.onData
      });
    },
    onData: function(data) {
      this.$vs.loading.close();

      if (data.ok) {
        this.$auth.setUserInfo(data.data);
      }
      this.currentUser = cloneDeep(this.user);

      return EventBus.$emit("flashDok", {
        type: data.ok ? "success" : "error",
        message: data.message
          ? "Modification effectuée avec succès"
          : "Erreur de modification",
        timerAlert: 8000
      });

      // this.$vs.notify({
      //   time: 4000,
      //   text: data.ok ? "Mis à jour avec succès" : data.message,
      //   color: data.ok ? "success" : "danger",
      //   iconPack: "feather",
      //   icon: "icon-check"
      // });
    },
    async refreshMe() {
      this.$store.dispatch("user/GET_PROFILE", {
        onData: this.refreshData
      });
    },
    refreshData(data) {
      this.isPaying = false;
      this.user = this.$auth.getUserInfo();
      this.currentUser = cloneDeep(this.user);
      this.loading = false;
    }
  }
};
</script>

<style></style>
